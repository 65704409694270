window.es_selectpicker = function(){
  $(".es-selectpicker").selectpicker().ajaxSelectPicker({
    ajax: {
      url: '/employees/' + $(".es-selectpicker").attr("id") +'/suggest_skills',
      type: 'GET',
      data: function () {
        var params = {
            text: '{{{q}}}'
        }; 
        return params;
      }
    },
    locale: {
      emptyTitle: 'Search for Skills...'
    },
    preprocessData: function(data){
      var skills = [];
      var len = data.length;
      for(var i = 0; i < len; i++){
        var skill = data[i];
        skills.push(
          {
          'value': skill.id,
          'text': skill.name,
          'disabled': false
          }
        );
      }
        
      return skills;
    },
    preserveSelected: false,
    ignoredKeys: ignored_keys_list(),
    emptyRequest:true
  });
}

window.e_shift_selectpicker = function(){
  $(".e-shift-selectpicker").selectpicker().ajaxSelectPicker({
    ajax: {
      url: '/employees/' + $(".e-shift-selectpicker").attr("id") +'/suggest_shifts',
      type: 'GET',
      data: function () {
        var params = {
            text: '{{{q}}}'
        }; 
        return params;
      }
    },
    locale: {
      emptyTitle: 'Search for Shifts...'
    },
    preprocessData: function(data){
      var shifts = [];
      var len = data.length;
      for(var i = 0; i < len; i++){
        var shift = data[i];
        shifts.push(
          {
          'value': shift.id,
          'text': shift.name + " - " + shift.duration,
          'disabled': false
          }
        );
      }
        
      return shifts;
    },
    preserveSelected: false,
    ignoredKeys: ignored_keys_list(),
    emptyRequest:true
  });
}

function getAge(dateString){
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())){
    age--;
  }
  return age;
}

$(document).on('turbolinks:load', function(){
  es_selectpicker();
  $(".selectpicker").selectpicker()
  $(".es-remove, .disable-shift").on("click", function(){
    var relation_id = $(this).attr("id");
    var employee_id = $(this).attr("data-employee-id");
    var action = $(this).attr("data-action");
    var url = "/employees/" + employee_id +"/" + action;
    $.post(url, {authenticity_token: $('[name="csrf-token"]')[0].content, relation_id: relation_id}, function (data, status) {
    });
  });
});
