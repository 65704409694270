$(document).on('turbolinks:load', function(){
  load_color_autocomplete();
  load_size_autocomplete();
});

window.load_size_autocomplete = function(){
  (function() {
    jQuery(function() {
      return $('#line_material_size').autocomplete({
        source: $('#line_material_size').data('autocomplete-source')
      });
    });

  }).call(this);
}

window.load_color_autocomplete = function(){
  (function() {
    jQuery(function() {
      return $('#line_material_color').autocomplete({
        source: $('#line_material_color').data('autocomplete-source')
      });
    });
  }).call(this);
}
