$(document).ready(function() {
  $(document).on('change', '.reasonCategorySelect', function() {
    let organisationId = $("#organisation_id").val();
    let reasonCategoryId = $(this).val();

    if(reasonCategoryId) {
      $.ajax({
        url: "/line_inspections/select_reason_category",
        data: { organisation_id: organisationId, reason_category_id: reasonCategoryId },
        context: this,
        type: 'GET',
        success: function(data) {
          let index = $(this).data('index');
          let reasonCategoryInput = $("[id=line_inspection_defects_attributes_"+index+"_reason_id]");
          let reasonInput = $("[id=line_inspection_defects_attributes_"+index+"_reason_detail_id]");

          reasonCategoryInput.html(data);
          reasonCategoryInput.addClass('selectpicker');
          reasonCategoryInput.attr('data-live-search', 'true');
          reasonCategoryInput.selectpicker('refresh');
          reasonInput.html("<option value=''>Select defect category</option>");
          reasonInput.addClass('selectpicker');
          reasonInput.attr('data-live-search', 'true');
          reasonInput.selectpicker('refresh');
        }
      });
    }
  });
  $(document).on('change', '.reasonSelect', function() {
    let organisationId = $("#organisation_id").val();
    let reasonId = $(this).val();

    if(reasonId) {
      $.ajax({
        url: "/line_inspections/select_reason",
        data: { organisation_id: organisationId, reason_id: reasonId },
        context: this,
        type: 'GET',
        success: function(data) {
          let index = $(this).data('index');
          let reasonInput = $("[id=line_inspection_defects_attributes_"+index+"_reason_detail_id]");

          reasonInput.html(data);
          reasonInput.addClass('selectpicker');
          reasonInput.attr('data-live-search', 'true');
          reasonInput.selectpicker('refresh');
        }
      });
    }
  });
  $('#line_inspection_found_defects').on('change', function() {
    if($(this).prop('checked')) {
      $('#defectsFromWrapper').removeClass('d-none');
    } else {
      $('#defectsFromWrapper').addClass('d-none');
    }
  });
  $('#line_inspection_inspected_pieces, #line_inspection_reject_pieces').on('change', function() {
    let inspectedPieces = parseInt($('#line_inspection_inspected_pieces').val()) || 0;
    let rejectPieces = parseInt($('#line_inspection_reject_pieces').val()) || 0;
    let passedPieces = inspectedPieces - rejectPieces;

    $('#passed_pieces').val(passedPieces);
  });
});
