window.obe_selectpicker = function(){
  $(".obe-selectpicker").selectpicker().ajaxSelectPicker({
    ajax: {
      url: '/operation_bulletins/suggest_employees',
      type: 'GET',
      data: function () {
        var params = {
          text: '{{{q}}}',
          obs_id: $(".obe-selectpicker").attr("id")
        };
        return params;
      }
    },
    locale: {
      emptyTitle: 'Search for Employees'
    },
    preprocessData: function(data){
      var employees = [];
      var len = data.length;
      for(var i = 0; i < len; i++){
        var employee = data[i];
        employees.push(
          {
          'value': employee.id,
          'text': employee.name + " - " + employee.employee_code,
          'disabled': false
          }
        );
      }
      return employees;
    },
    preserveSelected: false,
    ignoredKeys: ignored_keys_list(),
    emptyRequest:true
  });
}

window.om_selectpicker = function(){
  $(".om-selectpicker").selectpicker().ajaxSelectPicker({
    ajax: {
      url: '/operation_bulletins/suggest_machines',
      type: 'GET',
      data: function () {
        var params = {
          text: '{{{q}}}',
          obs_id: $(".om-selectpicker").attr("id")
        }; 
        return params;
      }
    },
    locale: {
      emptyTitle: 'Search for Machines...'
    },
    preprocessData: function(data){
      var machines = [];
      var len = data.length;
      for(var i = 0; i < len; i++){
        var machine = data[i];
        machines.push(
          {
          'value': machine.id,
          'text': machine.name + " - " + machine.serial_number,
          'disabled': false
          }
        );
      }
        
      return machines;
    },
    preserveSelected: true,
    preserveSelectedPosition:'after',
    ignoredKeys: ignored_keys_list(),
    emptyRequest:true
  });
}

window.load_steps_autocomplete = function(){
  (function() {
    jQuery(function() {
      return $('.step_name').autocomplete({
        source: $('.step_name').data('autocomplete-source')
      });
    });

  }).call(this);
}

$(document).on('turbolinks:load', function(){
  obe_selectpicker();
  om_selectpicker();
  load_steps_autocomplete();
  $(".obse-remove").on("click", function(){
    var relation_id = $(this).attr("id");
    var operation_bulletin_step_id = $(this).attr("data-operation-bulletin-step-id");
    var action = $(this).attr("data-action");
    var url = "/operation_bulletins/" + action;
    $.post(url, {authenticity_token: $('[name="csrf-token"]')[0].content, relation_id: relation_id, obs_id: operation_bulletin_step_id}, function (data, status) {
    });
  });

  $(document).on('nested:fieldAdded', function(event){
    load_steps_autocomplete();
  })
});
