$(document).on("turbolinks:load", function () {
  $(".prc-btn").on("click", function () {
    var subscription_id = $(this).attr("data-id")
    btnToggle(true)
    var purchase_url = "/purchases"
    fetch(purchase_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({subscription_id: subscription_id, authenticity_token: $('[name="csrf-token"]')[0].content})
    })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      if (data.success == false) {
        btnToggle(false)
        return showMsg(data.error, "failure")
      }
      if(data.checkout_data == undefined){
        $(location).attr('href', "/purchases")
      }else{
        return openRzpPopup(data)
      }
    })
    .catch(function (error) {
      btnToggle(false)
      if (error.status == 409){
        showMsg("Payment in progress", "failure")
      }else{
        showMsg(error.statusText, "failure")
      }
    });
  });
});

function btnToggle(flag){
  $(".prc-btn").attr("disabled", flag);
}

function openRzpPopup(rzp_data){
  rzp_data["checkout_data"]["handler"] =  function (response){
    // var url = "/purchases?organisation_id=" + rzp_data["checkout_data"]['organisation_id']
    var url = "/"
    $(location).attr('href', url)
  }

  modal_data = rzp_data["checkout_data"]["modal"]
  modal_data["ondismiss"] = function(){
    var params = {
      organisation_id: rzp_data["checkout_data"]['organisation_id'],
      purchase_id: rzp_data["checkout_data"]['purchase_id'],
      payment_number: rzp_data["checkout_data"]["notes"]["payment_number"],
      status: "Cancelled"
    }
    var url = "/purchases/"+ rzp_data["checkout_data"]['purchase_id'] +"/update_payment_status"
    // var redirect_url = "/purchases/new?organisation_id=" + rzp_data["checkout_data"]['organisation_id']
    var redirect_url = "/"
    $.post(url, params, function(data){

      if(data.success == true){
        $(location).attr('href', redirect_url)
      }else{
        btnToggle(false)
        showMsg("Payment Cancelled", "failure")
      }
    });
  }

  rzp_data["checkout_data"]["modal"] = modal_data
  var rzp = new Razorpay(rzp_data["checkout_data"]);
  rzp.open();
  rzp.on('payment.failed', function (response){
    var error_code = response.error.code
    var error_desc = response.error.description
    var error_source = response.error.source
    var error_step = response.error.step
    var error_reason = response.error.reason
    // url = "/purchases?organisation_id=" + rzp_data["checkout_data"]['organisation_id']
    url = "/" 
    btnToggle(false)
    showMsg(error_reason, "failure")
    $(location).attr('href', url)
    
  });

}
