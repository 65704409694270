$(document).on('turbolinks:load', function(){
  var barX = document.getElementById("barChart");

  if (barX !== null) {
    var barChart = new Chart(barX, {
      type: "bar",
      data: {
        labels: [
          "Jun 1",
          "Jun 2",
          "Jun 3",
          "Jun 4",
          "Jun 5",
          "Jun 6",
          "Jun 7",
          "Jun 8",
          "Jun 9",
          "Jun 10",
          "Jun 11",
          "Jun 12"
        ],
        datasets: [
          {
            label: "Machines",
            data: [20, 10, 20, 5, 15, 10, 25, 3, 32, 50, 10, 20],
            // data: [2, 3.2, 1.8, 2.1, 1.5, 3.5, 4, 2.3, 2.9, 4.5, 1.8, 3.4, 2.8],
            backgroundColor: "#4c84ff"
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                display: false
              },
              ticks: {
                display: false, // hide main x-axis line
                beginAtZero: true
              },
              barPercentage: 1.8,
              categoryPercentage: 0.2
            }
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: false, // hide main y-axis line
                display: false
              },
              ticks: {
                display: false,
                beginAtZero: true
              }
            }
          ]
        },
        tooltips: {
          titleFontColor: "#888",
          bodyFontColor: "#555",
          titleFontSize: 12,
          bodyFontSize: 15,
          backgroundColor: "rgba(256,256,256,0.95)",
          displayColors: false,
          borderColor: "rgba(220, 220, 220, 0.9)",
          borderWidth: 2
        }
      }
    });
  }
  // sensors count bar chart
  var sensor_barX = document.getElementById("sensorBarChart");

  if (sensor_barX !== null) {
    var sensorChart = new Chart(sensor_barX, {
      type: "bar",
      data: {
        labels: [
          "Jun 1",
          "Jun 2",
          "Jun 3",
          "Jun 4",
          "Jun 5",
          "Jun 6",
          "Jun 7",
          "Jun 8",
          "Jun 9",
          "Jun 10",
          "Jun 11",
          "Jun 12"
        ],
        datasets: [
          {
            label: "Sensors",
            data: [120, 150, 30, 100, 105, 95, 50, 100, 110, 70, 20, 100],
            // data: [2, 3.2, 1.8, 2.1, 1.5, 3.5, 4, 2.3, 2.9, 4.5, 1.8, 3.4, 2.8],
            backgroundColor: "#28a745"
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                display: false
              },
              ticks: {
                display: false, // hide main x-axis line
                beginAtZero: true
              },
              barPercentage: 1.8,
              categoryPercentage: 0.2
            }
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: false, // hide main y-axis line
                display: false
              },
              ticks: {
                display: false,
                beginAtZero: true
              }
            }
          ]
        },
        tooltips: {
          titleFontColor: "#888",
          bodyFontColor: "#555",
          titleFontSize: 12,
          bodyFontSize: 15,
          backgroundColor: "rgba(256,256,256,0.95)",
          displayColors: false,
          borderColor: "rgba(220, 220, 220, 0.9)",
          borderWidth: 2
        }
      }
    });
  }

  // site machine bar chart
  var sm_barX = document.getElementById("SiteBarChart");
  if (sm_barX !== null) {
    var SiteBarChart = new Chart(sm_barX, {
      type: "bar",
      data: {
        labels: [
          "Site 1",
          "Site 2",
          "Site 3",
          "Site 4",
          "Site 5",
        ],
        datasets: [
          {
            label: "Machines",
            data: [20, 10, 20, 5, 2],
            // data: [2, 3.2, 1.8, 2.1, 1.5, 3.5, 4, 2.3, 2.9, 4.5, 1.8, 3.4, 2.8],
            backgroundColor: "#4c84ff"
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                display: false
              },
              ticks: {
                display: false, // hide main x-axis line
                beginAtZero: true
              },
              barPercentage: 1.8,
              categoryPercentage: 0.2
            }
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: false, // hide main y-axis line
                display: false
              },
              ticks: {
                display: false,
                beginAtZero: true
              }
            }
          ]
        },
        tooltips: {
          titleFontColor: "#888",
          bodyFontColor: "#555",
          titleFontSize: 12,
          bodyFontSize: 15,
          backgroundColor: "rgba(256,256,256,0.95)",
          displayColors: false,
          borderColor: "rgba(220, 220, 220, 0.9)",
          borderWidth: 2
        }
      }
    });
  }

  // block machine bar chart
  var bm_barX = document.getElementById("blockBarChart");
  if (bm_barX !== null) {
    var blockBarChart = new Chart(bm_barX, {
      type: "bar",
      data: {
        labels: [
          "Block 1",
          "Block 2",
          "Block 3",
          "Block 4",
          "Block 5",
          "Block 6",
          "Block 7"
        ],
        datasets: [
          {
            label: "Machines",
            data: [5, 15, 2, 20, 18, 9,4],
            // data: [2, 3.2, 1.8, 2.1, 1.5, 3.5, 4, 2.3, 2.9, 4.5, 1.8, 3.4, 2.8],
            backgroundColor: "#17a2b8"
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                display: false
              },
              ticks: {
                display: false, // hide main x-axis line
                beginAtZero: true
              },
              barPercentage: 1.8,
              categoryPercentage: 0.2
            }
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: false, // hide main y-axis line
                display: false
              },
              ticks: {
                display: false,
                beginAtZero: true
              }
            }
          ]
        },
        tooltips: {
          titleFontColor: "#888",
          bodyFontColor: "#555",
          titleFontSize: 12,
          bodyFontSize: 15,
          backgroundColor: "rgba(256,256,256,0.95)",
          displayColors: false,
          borderColor: "rgba(220, 220, 220, 0.9)",
          borderWidth: 2
        }
      }
    });
  }

  // floor machine bar chart
  var fm_barX = document.getElementById("floorBarChart");
  if (fm_barX !== null) {
    var floorBarChart = new Chart(fm_barX, {
      type: "bar",
      data: {
        labels: [
          "Floor 1",
          "Floor 2",
          "Floor 3",
          "Floor 4",
          "Floor 5",
        ],
        datasets: [
          {
            label: "Machines",
            data: [15, 18, 5, 7, 13],
            // data: [2, 3.2, 1.8, 2.1, 1.5, 3.5, 4, 2.3, 2.9, 4.5, 1.8, 3.4, 2.8],
            backgroundColor: "#fe5461"
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                display: false
              },
              ticks: {
                display: false, // hide main x-axis line
                beginAtZero: true
              },
              barPercentage: 1.8,
              categoryPercentage: 0.2
            }
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: false, // hide main y-axis line
                display: false
              },
              ticks: {
                display: false,
                beginAtZero: true
              }
            }
          ]
        },
        tooltips: {
          titleFontColor: "#888",
          bodyFontColor: "#555",
          titleFontSize: 12,
          bodyFontSize: 15,
          backgroundColor: "rgba(256,256,256,0.95)",
          displayColors: false,
          borderColor: "rgba(220, 220, 220, 0.9)",
          borderWidth: 2
        }
      }
    });
  }

  // line machine bar chart
  var lm_barX = document.getElementById("lineBarChart");
  if (lm_barX !== null) {
    var lineBarChart = new Chart(lm_barX, {
      type: "bar",
      data: {
        labels: [
          "Line 1",
          "Line 2",
          "Line 3",
          "Line 4",
          "Line 5",
          "Line 6",
          "Line 7",
          "Line 8",
          "Line 9",
          "Line 10",
        ],
        datasets: [
          {
            label: "Machines",
            data: [12, 14, 28, 10, 5, 17, 22, 18, 12, 7],
            // data: [2, 3.2, 1.8, 2.1, 1.5, 3.5, 4, 2.3, 2.9, 4.5, 1.8, 3.4, 2.8],
            backgroundColor: "#28a745"
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                display: false
              },
              ticks: {
                display: false, // hide main x-axis line
                beginAtZero: true
              },
              barPercentage: 1.8,
              categoryPercentage: 0.2
            }
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: false, // hide main y-axis line
                display: false
              },
              ticks: {
                display: false,
                beginAtZero: true
              }
            }
          ]
        },
        "hover": {
        "animationDuration": 0
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 15,
          bottom: 0
        }
      },
      "animation": {
        "duration": 1,
        "onComplete": function() {
          var chartInstance = this.chart,
            ctx = chartInstance.ctx;

          ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';

          this.data.datasets.forEach(function(dataset, i) {
            var meta = chartInstance.controller.getDatasetMeta(i);
            meta.data.forEach(function(bar, index) {
              var data = dataset.data[index];
              ctx.fillText(data, bar._model.x, bar._model.y - 5);
            });
          });
        }
      },
        tooltips: {
          titleFontColor: "#888",
          bodyFontColor: "#555",
          titleFontSize: 12,
          bodyFontSize: 15,
          backgroundColor: "rgba(256,256,256,0.95)",
          displayColors: false,
          borderColor: "rgba(220, 220, 220, 0.9)",
          borderWidth: 2
        }
      }
    });
  }

  //  machine run time line chart
  var lmx = document.getElementById('linechartMachine');
  if (lmx !== null) {
    let years = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
    let times = [
      '11:46:07',
      '23:41:14',
      '15:55:26',
      '12:14:58',
      '5:54:55',
      '11:54:04',
      '10:28:29',
      '12:35:18',
      '8:54:55',
      '7:54:55',
      '11:54:55',
      '5:54:55',
    ];
    
    let data = years.map((day, index) => ({
      x: moment(`2020-06-${day}`),
      y: moment(`1970-02-01 ${times[index]}`).valueOf(),
    }));
    
    let bckColors = ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850", "#565452", "#321456", "#129864", "#326812", "#215984"];
    
    let lineChartMachine = new Chart(lmx, {
      type: 'line',
      data: {
        labels: [
          "1-Jun-2020",
          "2-Jun-2020",
          "3-Jun-2020",
          "4-Jun-2020",
          "5-Jun-2020",
          "6-Jun-2020",
          "7-Jun-2020",
          "8-Jun-2020",
          "9-Jun-2020",
          "10-Jun-2020",
          "11-Jun-2020",
          "12-Jun-2020"
        ],
        datasets: [
          {
            label: "Time",
            backgroundColor: 'rgba(188, 229, 214, 0.7)',
            pointBackgroundColor: bckColors,
            data: data,
            pointBorderWidth: 2,
            pointRadius: 5,
            pointHoverRadius: 7
          }
        ]
      },
      options: {
        responsive: true,
          // maintainAspectRatio: false,
          legend: {
            display: false
          },
          // layout: {
          //   padding: {
          //     right: 10
          //   }
          // },
          scales: {
              xAxes: [
                {
                  type: 'time',
                  position: 'bottom',
                  time: {
                    displayFormats: {
                      day: 'MMM D YYYY'
                    },
                    unit: 'day'
                  }
                }
              ],
              yAxes: [
                {
                  type: 'linear',
                  position: 'left',
                  ticks: {
                    min: moment('1970-02-01 00:00:00').valueOf(),
                    max: moment('1970-02-01 23:59:59').valueOf(),
                    stepSize: 3.6e+6,
                    beginAtZero: false,
                    callback: value => {
                      let date = moment(value);
                      if(date.diff(moment('1970-02-01 23:59:59'), 'minutes') === 0) {
                        return null;
                      }
                      
                      return date.format('h A');
                    }
                  }
                }
              ]
          }
      }
    });
  }
});
