window.hierarchy_selectpicker = function(){
  $(".h-selectpicker").selectpicker().ajaxSelectPicker({
    ajax: {
      url: '/sensors/suggest',
      type: 'GET',
      data: function () {
        var params = {
          text: '{{{q}}}',
          organisation_id: $(".h-selectpicker").attr("id"),
          sensor_type_id: $("#h-sensor-type").val()
        };
        return params;
      }
    },
    locale: {
      emptyTitle: 'Search for Sensors...'
    },
    preprocessData: function(data){
      var sensors = [];
      var len = data.length;
      for(var i = 0; i < len; i++){
        var sensor = data[i];
        sensors.push(
          {
          'value': sensor.id,
          'text': sensor.name + " - " + sensor.serial_number,
          'disabled': false
          }
        );
      } 
      return sensors;
    },
    preserveSelected: true,
    preserveSelectedPosition:'after',
    ignoredKeys: ignored_keys_list(),
    emptyRequest:true
  });
}

window.l_shift_selectpicker = function(){
  $(".ls-selectpicker").selectpicker().ajaxSelectPicker({
    ajax: {
      url: '/hierarchies/' + $(".ls-selectpicker").attr("data-h-id") +'/suggest_shifts',
      type: 'GET',
      data: function () {
        var params = {
          text: '{{{q}}}'
        }; 
        return params;
      }
    },
    locale: {
      emptyTitle: 'Search for Shifts...'
    },
    preprocessData: function(data){
      var shifts = [];
      var len = data.length;
      for(var i = 0; i < len; i++){
        var shift = data[i];
        shifts.push(
          {
          'value': shift.id,
          'text': shift.name + " - " + shift.duration,
          'disabled': false
          }
        );
      }
        
      return shifts;
    },
    preserveSelected: false,
    ignoredKeys: ignored_keys_list(),
    emptyRequest:true
  });
}

$(document).on('turbolinks:load', function(){
  hierarchy_selectpicker();
  l_shift_selectpicker();
  $(".selectpicker").selectpicker()
  $(".hs-deactivate").on("click", function(){
    var sensor_hierarchy_id = $(this).attr("id");
    var hierarchy_id = $(this).attr("data-hierarchy-id");
    var url = "/hierarchies/" + hierarchy_id +"/deactivate_sensor";
    $.post(url, {authenticity_token: $('[name="csrf-token"]')[0].content, sensor_hierarchy_id: sensor_hierarchy_id}, function (data, status) { });
  })
});
