window.get_used_machine_ids = function(origJQElement) {
  var selMachines = []
  $('.machineAutoSelect').not(origJQElement).each(function (index, obj) {
    var text_name = $(this).attr("name").replace('_text', '')
    selMachines.push($("[name='" + text_name + "']").val())
  });
  return selMachines
}

window.get_used_employee_ids = function(origJQElement) {
  var selEmployees = []
  $('.employeeAutoSelect').not(origJQElement).each(function (index, obj) {
    var text_name = $(this).attr("name").replace('_text', '')
    selEmployees.push($("[name='" + text_name + "']").val())
  });
  return selEmployees
}

window.get_sel_member_ids = function(origJQElement) {
  var selEmployees = []
  $('.memberAutoSelect').not(origJQElement).each(function (index, obj) {
    var text_name = $(this).attr("name").replace('_text', '')
    selEmployees.push($("[name='" + text_name + "']").val())
  });
  return selEmployees
}

/// Adding default sequence number & name
$(document).on('nested:fieldAdded', function (event) {
  var seq_ele = event.field.find('.seq_no');
  seq_ele.val($(".seq_no:visible").length);
  var name_ele = event.field.find('.step_name');
  name_ele.val("step_" + $(".seq_no:visible").length);
});

// Added recursive function to add n steps instead of default nested field event as default nested event for multiple fields won't provide uniq ID for the elements
$(document).on("click", "#mutli-steps-line", function () {
  if (!$(this).hasClass("initial")) {
    $(this).addClass("initial");
    var steps = $(this).data("predefined-steps")
    for (var n = (steps - 1); n; n--) {
      if (n == 1) {
        $(this).addClass("final")
      }
      $("#mutli-steps-line").click();
    }
  } else {
    if ($("#mutli-steps-line").hasClass("final")) {
      $("#mutli-steps-line").removeClass("final initial")
    }
  }
})

$(document).on('nested:fieldRemoved', function (event) {
  update_seq()
});

window.update_seq = function() {
  $('.seq_no:visible').each(function (i, obj) {
    var seq = i + 1;
    $(this).val(seq);
  });
}

// Displays the job role of the employee
$(document).on('autocomplete.select', '.memberAutoSelect', function(evt, item){
  if(item != undefined){
    var url = "/employees/"+ item.value +"/role";
    var ele = evt.currentTarget
    var id = ele.id
    var taget_ele = $("#" + id).parents(".col-sm").next();
    $.get(url, function(data){
      taget_ele.html(data)
    })
  }
})
