window.add_daterangepicker = function(){
  $('.timepicker').daterangepicker({
        timePicker : true,
        singleDatePicker:true,
        timePicker24Hour : true,
        timePickerIncrement : 1,
        locale : {
            format : 'HH:mm'
        }
    }).on('show.daterangepicker', function(ev, picker) {
        picker.container.find(".calendar-table").hide();
  });
}
$(function(){
  add_daterangepicker();
  add_singledate_timerangepicker();
  add_date_timerangepicker();

  $(document).on('nested:fieldAdded', function(event){
    add_daterangepicker();
    add_date_timerangepicker();
  })

  $(document).on('turbolinks:load', function(){
    add_singledate_timerangepicker();
    add_date_timerangepicker();
  });

})

window.add_singledate_timerangepicker = function(){
  $('.single-date-timepicker').daterangepicker({
    timePicker: true,
    singleDatePicker:true,
    timePickerSeconds: true,
    timePicker24Hour : true,
    timePickerIncrement : 1,
    minDate: moment().startOf('day'),
    maxDate: moment().endOf('day'),
    locale: {
      format: 'DD/MM/YYYY HH:mm:ss.SSS'
    }
  });
}

window.add_date_timerangepicker = function(){
  $('.date-timepicker').daterangepicker({
    timePickerIncrement : 1,
    timePicker: true,
    singleDatePicker:true,
    timePickerSeconds: true,
    timePicker24Hour : true,
    timePickerIncrement : 1,
    showDropdowns: true,
    minDate: "-90d",
    maxDate: moment().endOf('day'),
    locale: {
      format: 'DD/MM/YYYY HH:mm:ss.SSS'
    }
  });
}
