$(document).on("turbolinks:load", function () {
  lm_selectpicker();
  le_selectpicker();
  $(".temp-deactivate").on("click", function () {
    if (confirm("Are you sure you want to deactivate?")) {
      var id = $(this).attr("id");
      var line_id = $(this).attr("data-line-id");
      var url = "/work_lines/" + line_id +"/line_mappings/" + id + "/deactivate";
      $.post(
        url,
        { authenticity_token: $('[name="csrf-token"]')[0].content },
        function (data, status) {
          if(data.success == false){
            showMsg(data.error, "failure")
          }
        }
      );
    }
  });

  $(".lm-shift-disable").on("click", function () {
    if (confirm("Are you sure you want to disable?")) {
      var id = $(this).attr("id");
      var line_id = $(this).attr("data-line-id");
      var url = "/work_lines/"+ line_id +"/line_mappings/disable_shift";
      $.post(
        url,
        {
          authenticity_token: $('[name="csrf-token"]')[0].content,
          hierarchy_id: line_id,
          shift_id: id,
        },
        function (data, status) {
          if(data.success == false){
            showMsg(data.error, "failure")
          }
        }
      );
    }
  });

  $(".remap").on("click", function () {
    var id = $(this).attr("id");
    var line_id = $(this).attr("data-line-id");
    var url = "/work_lines/"+ line_id +"/line_mappings/" + id + "/remap";
    $.post(
      url,
      { authenticity_token: $('[name="csrf-token"]')[0].content },
      function (data, status) {
        if(data.success == false){
          showMsg(data.error, "failure")
        }
      }
    );
  });
});

window.lm_selectpicker = function(){
  $(".lm-selectpicker").selectpicker().ajaxSelectPicker({
    ajax: {
      url: '/work_lines/' + $(".lm-selectpicker").attr("data-h-id") +'/line_mappings/suggest_step_machines',
      type: 'GET',
      data: function () {
        var params = {
          text: '{{{q}}}',
          shift_id: $("#shift").val(),
          operation_bulletin_step_id: $("#ob-steps").val()
        };
        return params;
      }
    },
    locale: {
      emptyTitle: 'Search for Machines...',
      statusNoResults: $(".lm-selectpicker").attr("data-noresult-text")
    },
    preprocessData: function(data){
      var machines = [];
      var len = data.length;
      for(var i = 0; i < len; i++){
        var machine = data[i];
        machines.push(
          {
          'value': machine.id,
          'text': machine.name + " - " + machine.serial_number,
          'disabled': false
          }
        );
      }
      return machines;
    },
    preserveSelected: false,
    ignoredKeys: ignored_keys_list(),
    emptyRequest:true
  });
}


window.le_selectpicker = function(){
  $(".le-selectpicker").selectpicker().ajaxSelectPicker({
    ajax: {
      url: '/work_lines/' + $(".le-selectpicker").attr("data-h-id") +'/line_mappings/suggest_employees',
      type: 'GET',
      data: function () {
        var params = {
            text: '{{{q}}}',
          obs_id: $(".obe-selectpicker").attr("id")
        };
        return params;
      }
    },
    locale: {
      emptyTitle: 'Search for Employees',
      statusNoResults: $(".le-selectpicker").attr("data-noresult-text")
    },
    preprocessData: function(data){
      var employees = [];
      var len = data.length;
      for(var i = 0; i < len; i++){
        var employee = data[i];
        employees.push(
          {
          'value': employee.id,
          'text': employee.name + " - " + employee.employee_code,
          'disabled': false
          }
        );
      }
      return employees;
    },
    preserveSelected: false,
    ignoredKeys: ignored_keys_list(),
    emptyRequest:true
  });
}
