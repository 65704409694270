import consumer from "./consumer"
import Rails from '@rails/ujs'; // Use to make an ajax post request to Rails

$(document).on('turbolinks:load', function () {
  if ($("meta[name='unauthenticated_user']").length > 0){
    consumer.subscriptions.create({
        channel: "SmartSigninChannel",
        login_id: $("#smart-login-id").attr("data-scan-id")
      }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        let formData = new FormData();
        formData.append("user_data", data.user_data);
        Rails.ajax({
          url: "/login",
          type: "post",
          data: formData,
        });
      }
    });
  }
});
