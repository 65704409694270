$(document).on("change", "#live_report_organisation_id", function() {
  let organisation_id = $(this).val();

  $.get("/work_lines/org_lines", {organisation_id: organisation_id}, function(data) {
    $("[id ^=live_report_report_items_attributes_][id $=_work_line_id]").html(data);
    $("[id ^=live_report_report_items_attributes_][id $=_work_line_id]").addClass('selectpicker');
    $("[id ^=live_report_report_items_attributes_][id $=_work_line_id]").attr('data-live-search', 'true');
    $("[id ^=live_report_report_items_attributes_][id $=_work_line_id]").selectpicker('refresh');
  });
  $.get("/hierarchies/org_area_options", {organisation_id: organisation_id}, function(data) {
    $("[id ^=live_report_report_items_attributes_][id $=hierarchy_id]").html(data);
    $("[id ^=live_report_report_items_attributes_][id $=hierarchy_id]").addClass('selectpicker');
    $("[id ^=live_report_report_items_attributes_][id $=hierarchy_id]").attr('data-live-search', 'true');
    $("[id ^=live_report_report_items_attributes_][id $=hierarchy_id]").selectpicker('refresh');
  });
  $.get("/material_tracking/steps/org_step_template_options", {organisation_id: organisation_id}, function(data) {
    $("[id ^=live_report_report_items_attributes_][id $=mt_step_id]").html(data);
    $("[id ^=live_report_report_items_attributes_][id $=mt_step_id]").addClass('selectpicker');
    $("[id ^=live_report_report_items_attributes_][id $=mt_step_id]").attr('data-live-search', 'true');
    $("[id ^=live_report_report_items_attributes_][id $=mt_step_id]").selectpicker('refresh');
  });
});

$(document).on('nested:fieldAdded', function(event){
  $(".selectpicker").selectpicker();
  var options = $("[id ^=live_report_report_items_attributes_][id $=_work_line_id] > option").clone();
  var $options = $("#myselect > option").clone();
  var line_ele = event.field.find('[id ^=live_report_report_items_attributes_][id $=_work_line_id]');
  line_ele.html(options);
  line_ele.addClass('selectpicker');
  line_ele.attr('data-live-search', 'true');
  line_ele.val($('#live_report_report_items_attributes_0_work_line_id').val());
  line_ele.selectpicker('refresh');
})


$('#lrCarousel').on('slid.bs.carousel', function () {
  var duration = $('#lrCarousel').find('.carousel-item.active').attr('data-interval');

  $('#lrCarousel').carousel({
    "interval": parseInt(duration)
  })
})
