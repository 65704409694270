var refresh_time = 60000
$(document).on('turbolinks:load', function(){
  if($(".users-tb").find(".dataTables_wrapper").length == 0){
    $(".user").DataTable({
      responsive: true,
      language: { searchPlaceholder: "Search..." },
      "fnDrawCallback": function(oSettings) {
        var rw_count = $(".users-tb tr").length
        var info = this.api().page.info();
        var count = info.recordsTotal
        if (count < 11 || count < rw_count) {
          $('.dataTables_paginate').hide();
        }else{
          $('.dataTables_paginate').show();
        }
      }
    })
  }
})


window.refreshQrCode = function(){
  var qrcode_url = "/pages/update_qrcode"
  var login_id = $("#smart-login-id").attr("data-scan-id");
  $.post(qrcode_url, {format: "js", login_id: login_id}, function(data){})
  setTimeout(refreshQrCode, refresh_time)
}
