// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("jquery")
require("popper.js")
require("moment")
require("channels")
require("chart.js")
require("chartjs-plugin-datalabels")
require("jquery-ui")
require("bootstrap-daterangepicker")
require("jquery-mask-plugin")
require("jquery-slimscroll")
require("ladda")
require("select2")
require("bootstrap-select")
require("ajax-bootstrap-select")
require("simplebar")
require("datatables.net")
require("datatables.net-bs4")
require("packs/dashboard")
require("packs/defects")
require("packs/users")
require("packs/nested_form")
require("packs/organisations")
require("packs/hierarchies")
require("packs/machines")
require("packs/employees")
require("packs/line_mappings")
require("packs/operation_bulletin_steps")
require("packs/sleek.bundle")
require("packs/fobs")
require("packs/sensors")
// require("chartkick")
require("highcharts")
require("highcharts/modules/heatmap")
require("highcharts/modules/solid-gauge")
require("flatpickr")
require("flatpickr/dist/flatpickr.css")
require('highcharts/highcharts-more');
require("highcharts/highstock")
require("bootstrap-autocomplete")
require("intl-tel-input")
require("packs/emp_inputs")
require("packs/line_steps_mapping")
require("packs/line_materials")
require("packs/line_inspections")
require("packs/live_reports")
require("packs/purchases")
require("packs/skus")
require("packs/coupon_templates")
require('data-confirm-modal')
require('packs/cost_estimates')
//= require tinymce


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap'
import NProgress from 'nprogress/nprogress.js';
import Highcharts from 'highcharts'
import Heatmap from 'highcharts/modules/heatmap.js'
import solidGuage from 'highcharts/modules/solid-gauge.js'
import highchartsMore from "highcharts/highcharts-more.js"
import data from 'highcharts/modules/data.js'
import seriesLabel from 'highcharts/modules/series-label.js'
import exporting from 'highcharts/modules/exporting.js'
import bullet from 'highcharts/modules/bullet.js'
import exportData from 'highcharts/modules/export-data.js'
import $ from 'jquery'
import 'bootstrap/dist/js/bootstrap.bundle';
import DataTable from 'datatables.net-bs4';
import flatpickr from 'flatpickr';
import moment from 'moment';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import BoxPlotSeries from "highcharts/es-modules/Series/BoxPlotSeries";
import HighStock from 'highcharts/modules/stock'
import accessibility from 'highcharts/modules/accessibility'
import HighchartsMore from 'highcharts/highcharts-more';
import intlTelInput from 'intl-tel-input';
import utilsScript from 'intl-tel-input/build/js/utils.js?1613236686837'
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';

window.$ = window.JQuery = $;
window.Chart = Chart;
window.ChartDataLabels = ChartDataLabels;
window.NProgress = NProgress;
window.moment = moment;
window.DataTable = DataTable;
window.Highcharts = Highcharts;
window.intlTelInput = intlTelInput;
window.utilsScript = utilsScript;
window.Sortable = Sortable;

Heatmap(Highcharts);
highchartsMore(Highcharts);
solidGuage(Highcharts);
data(Highcharts);
bullet(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
accessibility(Highcharts);

document.addEventListener("turbolinks:load", () => {
  flatpickr("[data-behavior='flatpickr']", {
    enableTime: true,
    time_24hr: true,
    altInput: true,
    altFormat: "d M Y H:i",
    dateFormat: "Y-m-d H:i",
    allowInput: true
  });

  $(".ajaxLoadingTab").on("click", function() {
    let url = $(this).data("url");
    let isLoaded = $(this).data("loaded");

    if(url && !isLoaded) {
      $.ajax({
        url: url,
        type: "GET",
        dataType : "SCRIPT",
        context: this,
        success: function (response) {
          // Rendering partials from related js.erb view file
          $(this).data("loaded", true);
        },
        error: function () {
        }
      });
    }
  });
});

$(document).on('turbolinks:load', function () {
  $(".selectpicker").selectpicker()
  participants_delete_actions();
  bootstrapFloatLabel();
  showTime();
  multiItemCarousel();
  $('.dataTable').DataTable({
    "aLengthMenu": [[20, 30, 50, 75, -1], [20, 30, 50, 75, "All"]],
    "pageLength": 20,
    "dom": '<"row justify-content-between top-information"lf>rt<"row justify-content-between bottom-information"ip><"clear">'
  });
});
$(document).on("turbolinks:before-cache", function () {
  $('.selectpicker').selectpicker('destroy').addClass('selectpicker')
  $(".sidebar-scrollbar").slimScroll({ destroy: true });
});


window.showMsg = function (message, type) {
  var time = 0;
  $(".floating-alert-list").remove();
  $(".content").prepend("<div class='floating-alert-list'><div class='container alert alert-dismissible fade show lp-alert floating-alert'></div></div>");
  if (type == 'failure') {
    $(".lp-alert").addClass('alert-danger');
    time = 8000;
  } else if (type == 'alert') {
    $(".lp-alert").addClass('alert-primary');
    time = 4000;
  } else if (type == 'info') {
    $(".lp-alert").addClass('alert-info');
    time = 3000;
  } else if (type == 'success') {
    $(".lp-alert").addClass('alert-success');
    time = 2500;
  }
  $(".lp-alert").html(message + '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>');
  $(".lp-alert").show();
  $(".lp-alert").delay(time).fadeOut(300);
}


window.get_page_num = function (url_params) {
  var pg = 1;
  $.each(url_params, function (i, v) {
    var index = v.indexOf("page=")
    if (index > -1) {
      pg = v.split('page=')[1];
      return pg;
    }
  });
  return pg;
}

$(function () {
  $(':input[type=number]').on('mousewheel', function (e) { $(this).blur(); });
});


window.ignored_keys_list = function () {
  var list = {
    9: "tab",
    16: "shift",
    17: "ctrl",
    18: "alt",
    27: "esc",
    37: "left",
    39: "right",
    38: "up",
    40: "down",
    91: "meta"
  }
  return list
}

$(document).on('nested:fieldAdded', function (event) {
  bootstrapFloatLabel();
})
$(document).on('click', '.remove_nested_fields', function (event) {
  handleNestedFieldRemoved(this);
})

$(document).ready(function () {
  bootstrapFloatLabel();
  showTime();
  multiItemCarousel();
  $(".selectpicker").selectpicker()
})


// Floating labels
function bootstrapFloatLabel() {
  // Setting label text as placeholder
  $("form .float-label input").each(function () {
    var placeholder = $(this).attr('placeholder');
    var label = $(this).parent().find("label").text();

    if (placeholder === undefined || placeholder === "") {
      $(this).attr("placeholder", label);
    }
    var val = $(this).val();
    if (val != undefined && val != "") {
      $(this).parent().find("label").addClass("has-float-label");
    }
  });

  // Floating label on focus
  $("form .float-label input").focus(function (e) {
    $(this).attr('placeholder', "");
    $(this).parent().find("label").addClass("has-float-label");
  });

  // Floating label on blur
  $("form .float-label input").blur(function (e) {
    var label_ele = $(this).parent().find("label");
    $(this).attr("placeholder", label_ele.text());
    if ($(this).val() !== "") {
      label_ele.not("has-float-label").addClass("has-float-label");
    } else {
      label_ele.removeClass("has-float-label");
    }
  });

  $("form .float-label select").change(function (e) {
    var label_ele = $(this).parent().find("label");
    $(this).attr("placeholder", label_ele.text());
    if ($(this).val() !== "") {
      label_ele.not("has-float-label").addClass("has-float-label");
    } else {
      label_ele.removeClass("has-float-label");
    }
  });
}

function handleNestedFieldRemoved(target) {
  let removedInputsWrapper = $(target).parents(".fields");

  removedInputsWrapper.find("input.required, select.required, textarea.required").each(function () {
    if($(this).val() == "") {
      $(this).removeAttr("required");
    }
  });
}

function showTime() {
  var date = new Date();
  var h = date.getHours(); // 0 - 23
  var m = date.getMinutes(); // 0 - 59
  var s = date.getSeconds(); // 0 - 59

  h = (h < 10) ? "0" + h : h;
  m = (m < 10) ? "0" + m : m;
  s = (s < 10) ? "0" + s : s;

  var time = h + ":" + m + ":" + s + " ";
  $("p.clock").html(time)

  setTimeout(showTime, 1000);
}

var itemsMainDiv = ('.MultiCarousel');
var itemsDiv = ('.MultiCarousel-inner');
var itemWidth = "";

function multiItemCarousel() {
  $('.leftLst, .rightLst').click(function () {
    var condition = $(this).hasClass("leftLst");
    if (condition)
      click(0, this);
    else
      click(1, this)
  });

  ResCarouselSize();
}




$(window).resize(function () {
  ResCarouselSize();
});

//this function define the size of the items
function ResCarouselSize() {
  var incno = 0;
  var dataItems = ("data-items");
  var itemClass = ('.item');
  var id = 0;
  var btnParentSb = '';
  var itemsSplit = '';
  var sampwidth = $(itemsMainDiv).width();
  var bodyWidth = $('body').width();
  $(itemsDiv).each(function () {
    id = id + 1;
    var itemNumbers = $(this).find(itemClass).length;
    btnParentSb = $(this).parent().attr(dataItems);
    itemsSplit = btnParentSb.split(',');
    $(this).parent().attr("id", "MultiCarousel" + id);

    if (bodyWidth >= 1200) {
      incno = itemsSplit[2];
      itemWidth = sampwidth / incno;
    }
    else if (bodyWidth >= 992) {
      incno = itemsSplit[2];
      itemWidth = sampwidth / incno;
    }
    else if (bodyWidth >= 768) {
      incno = itemsSplit[1];
      itemWidth = sampwidth / incno;
    }
    else {
      incno = itemsSplit[0];
      itemWidth = sampwidth / incno;
    }
    $(this).css({ 'transform': 'translateX(0px)', 'width': itemWidth * itemNumbers });
    $(this).find(itemClass).each(function () {
      $(this).outerWidth(itemWidth);
    });

    $(".leftLst").addClass("over");
    $(".rightLst").removeClass("over");
  });
}


//this function used to move the items
function ResCarousel(e, el, s) {
  var leftBtn = ('.leftLst');
  var rightBtn = ('.rightLst');
  var translateXval = '';
  var divStyle = $(el + ' ' + itemsDiv).css('transform');
  var values = divStyle.match(/-?[\d\.]+/g);
  var xds = Math.abs(values[4]);
  if (e == 0) {
    translateXval = parseInt(xds) - parseInt(itemWidth * s);
    $(el + ' ' + rightBtn).removeClass("over");

    if (translateXval <= itemWidth / 2) {
      translateXval = 0;
      $(el + ' ' + leftBtn).addClass("over");
    }
  }
  else if (e == 1) {
    var itemsCondition = $(el).find(itemsDiv).width() - $(el).width();
    translateXval = parseInt(xds) + parseInt(itemWidth * s);
    $(el + ' ' + leftBtn).removeClass("over");

    if (translateXval >= itemsCondition - itemWidth / 2) {
      translateXval = itemsCondition;
      $(el + ' ' + rightBtn).addClass("over");
    }
  }
  $(el + ' ' + itemsDiv).css('transform', 'translateX(' + -translateXval + 'px)');
}

//It is used to get some elements from btn
function click(ell, ee) {
  var Parent = "#" + $(ee).parent().attr("id");
  var slide = $(Parent).attr("data-slide");
  ResCarousel(ell, Parent, slide);
}

document.addEventListener("turbolinks:load", () => {
  if (!$.fn.DataTable.isDataTable('.datatable')) {
    $('.datatable').DataTable({
        "aLengthMenu": [[10, 20, 30, 50, 75, -1], [10, 20, 30, 50, 75, "All"]],
        "order": [[0, 'desc']],
        "pageLength": 10,
        "dom": '<"row justify-content-between top-information"lf>rt<"row justify-content-between bottom-information"ip><"clear">',
        "orderCellsTop": true,  // This will make DataTables apply the sorting event listeners to the first row in the header.
        initComplete: function () {
          this.api().columns('.filter-enabled').every(function () {
            let column = this;

            // Create select element
            let select = document.createElement('select');
            select.add(new Option('All', ''));

            // Replace the filter cells in the filter row
            $(column.header()).parent().siblings('.filter-row').children().eq(column.index()).append(select);

            // Apply listener for user change in value
            $(select).on('change.select2', function () {
              var val = DataTable.util.escapeRegex($(this).val());
              column
                  .search(val ? '^' + val + '$' : '', true, false)
                  .draw();
          });

            // select.addEventListener('change', function () {
            //     var val = DataTable.util.escapeRegex(select.value);
            //     column
            //         .search(val ? '^' + val + '$' : '', true, false)
            //         .draw();
            // });

            // Add list of options
            column.data().unique().sort().each(function (d, j) {
              if(d==''){
                return
              }
              select.add(new Option(d));
            });
            $(select).addClass('select2')
          });
        }
    });
  }

  document.addEventListener("turbolinks:before-cache", () => {
    $('.datatable').DataTable().destroy();
  });
});


document.addEventListener("turbolinks:load", () => {
  $('.select2').select2();
  bindClickEventOnSelectDeselectAllOptionCheckbox()
});

function bindClickEventOnSelectDeselectAllOptionCheckbox() {
  $('.selectDeselectCheckbox').on('change', function() {
    var clickedCheckbox = $(this)
    var select2Selector = clickedCheckbox.attr('data-select2-selector')
    if($(select2Selector).length > 0){
      if(clickedCheckbox.prop('checked')){
        if(!$(select2Selector).prop('disabled')){
          var allOptions = $(select2Selector + ' option').map(function() {
            return this.value;
          }).get();

          $(select2Selector).val(allOptions).trigger('change');
        }
      }else {
        $(select2Selector).val(null).trigger('change');
      }
    }
  })
}
