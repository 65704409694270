window.sensors_selectpicker = function(){
  $(".f-selectpicker").selectpicker().ajaxSelectPicker({
    ajax: {
      url: '/sensors/suggest',
      type: 'GET',
      data: function () {
        var params = {
          text: '{{{q}}}',
          organisation_id: $(".f-selectpicker").attr("id")
        };
        return params;
      }
    },
    locale: {
      emptyTitle: 'Search for Sensors...'
    },
    preprocessData: function(data){
      var sensors = [];
      var len = data.length;
      for(var i = 0; i < len; i++){
        var sensor = data[i];
        sensors.push(
          {
          'value': sensor.id,
          'text': sensor.name + " - " + sensor.serial_number,
          'disabled': false
          }
        );
      } 
      return sensors;
    },
    preserveSelected: true,
    preserveSelectedPosition:'after',
    ignoredKeys: ignored_keys_list(),
    emptyRequest:true
  });
}

$(document).on('turbolinks:load', function(){
  sensors_selectpicker();
  $(".fs-deactivate").on("click", function(){
    var sensor_fob_id = $(this).attr("id");
    var fob_id = $(this).attr("data-fob-id");
    var url = "/fobs/" + fob_id +"/deactivate_sensor";
    $.post(url, {authenticity_token: $('[name="csrf-token"]')[0].content, sensor_fob_id: sensor_fob_id}, function (data, status) { });
  })
});
