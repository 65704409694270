$(document).on("turbolinks:load", function () {
  $(".pos-untag").on("click", function () {
    if (confirm("Are you sure you want to untag?")) {
      var entity_id = $(this).attr("id");
      var sensor_id = $(this).attr("data-sensor-id");
      var url = "/sensors/" + sensor_id + "/" + "untag_position";
      $.post(
        url,
        {
          authenticity_token: $('[name="csrf-token"]')[0].content,
          entity_id: entity_id,
        },
        function (data, status) {}
      );
    }
  });
});

window.suggest_positions = function (position_id, position_text, filter_data){
  $('.position_name').autoComplete({
    resolver: 'custom',
    minLength: 1,
    events: {
      search: function (qry, callback, origJQElement) {
        $.ajax(
          '/positions/suggest',
          {
            data: {
              'text': qry,
              entity_type: filter_data.entity_type,
              entity_id: filter_data.entity_id,
              organisation_id: filter_data.organisation_id,
              hierarchy_id: filter_data.hierarchy_id,
              selected_pos_ids: get_selected_pos_ids(origJQElement)
            }
          }
        ).done(function (res) {
          callback(res)
        });
      }
    }
  });

  if(position_id != "" && position_text != undefined && position_text != "" && position_text != undefined){
    $('.position_name').autoComplete('set', { value: position_id, text: position_text});
  }
}

function get_selected_pos_ids(origJQElement){
  var selPositions = []
  var position_elements = $('.position_name').not(origJQElement)
  if ( position_elements.length > 0 ){
    position_elements.each(function(index, obj){
      var text_name = $(this).attr("name").replace('_text','')
      selPositions.push($("[name='" +  text_name +"']").val())
    });
  }
  return selPositions
}

window.participants_delete_actions = function(){
  $("#selectAllSensors").on("change", function(){
    var checked =  this.checked
    if(checked){
      $(this).css("opacity", 1)
    }else{
      $(this).css("opacity", 0.5)
    }
    $('.s-pos').each(function(i, ele) {
      $(ele).prop("checked", checked)
      if(checked){
        $(ele).css("opacity", 1)
      }else{
        $(ele).css("opacity", 0.5)
      }
    });
    toggle_delete_button(checked)
  })

  $(".s-pos").on("change", function(){
    var checked =  this.checked
    $(this).prop("checked", checked)
    var checked_participants_count = $('.s-pos:checkbox:checked').length;
    if(checked){
      $(this).css("opacity", 1)
    }else{
      $(this).css("opacity", 0.5)
      $("#selectAllSensors").prop("checked", false)
      $("#selectAllSensors").css("opacity", 0.5)
    }
    toggle_delete_button(checked_participants_count > 0)
  })

  function toggle_delete_button(flag){
    if(flag){
      $(".delete-all").attr("disabled", false)
      $(".delete-all").removeClass("btn-outline-secondary")
      $(".delete-all").addClass("btn-outline-danger")
    }else{
      $(".delete-all").removeClass("btn-outline-danger")
      $(".delete-all").addClass("btn-outline-secondary")
      $(".delete-all").attr("disabled", true)
    }
  }

  $(".delete-all").on("click", function () {
    if (confirm("Are you sure you want to untag the position from the selected sensors?")) {
      $(this).attr("disabled", true)
      var sensor_ids = []
      $('.s-pos:checkbox:checked').each(function(i, ele) {
        var sensor_id = $(this).attr("id");
        sensor_ids.push(sensor_id)
      });
      var url = "/sensors/bulk_untag_positions";
      $.post(
        url,
        {
          authenticity_token: $('[name="csrf-token"]')[0].content,
          sensor_ids: sensor_ids
        },
        function (data, status) {}
      );
    }
  });
}
