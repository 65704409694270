$(document).on('turbolinks:load', function() {
  initFormChangeDetection();
  setupEntries();
  fetchFabricTypes();
  fetchFabricationProcess();
  // $('select').select2();
});

function initFormChangeDetection() {
  $('#costEstimateForm').on('change', 'input, textarea, select', function() {
    clearTimeout($.data(this, 'formTimer'));
    var formTimer = setTimeout(saveFormData, 500);
    $.data(this, 'formTimer', formTimer);
  });
}

function setupEntries() {
  addPomEntry();
  addSizeEntry();
  addQuantityEntry();
  addMeasurementEntry();
  addPanelEntry();
  addTrimEntry();
  addFabricEntry();
  updateQuantityColorDropdowns();
}

function setupEntryRemoval(listId, removeButtonClass, labelClass) {
  $(listId).on('click', removeButtonClass, function() {
    $(this).closest('.form-group').remove();
    saveFormData();

    if ($(listId).children('.form-group').length === 0 && labelClass) {
      $(listId).children('.' + labelClass).remove();
    }
  });
}

function saveFormData() {
  var formData = new FormData($('#costEstimateForm')[0]);
  $.ajax({
    url: '/cost_estimates',
    type: 'POST',
    data: formData,
    processData: false,
    contentType: false,
    dataType: 'json',
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    },
    success: function(response) {
      console.log('Data saved successfully:', response);
      $('#message-box').removeClass('alert-danger').addClass('alert-success').html('Data saved successfully.').show();
      if(response.id) {
        $('#costEstimateId').val(response.id);
      }
      setTimeout(function() {
        $('#message-box').fadeOut('slow');
      }, 5000);
    },
    error: function(xhr, status, error) {
      console.error('Error saving data:', error);
      var response = JSON.parse(xhr.responseText); // Parsing the JSON response
      var errorMessages = response.errors.join('<br>'); // Join errors into a single string
      $('#message-box').removeClass('alert-success').addClass('alert-danger').html(errorMessages).show();
    }
  });
}

function getAllColors() {
  var colors = $('#color').val();
  return colors || [];
}

function getAllSizes() {
  var sizes = [];
  $('input[name="cost_estimate[size][]"]').each(function() {
    var sizeValue = $(this).val();
    if (sizeValue) sizes.push(sizeValue);
  });
  return sizes;
}

function getAllPomAttributes() {
  var pomAttributes = [];
  $('#pom-entry-list .form-group').each(function() {
    var pom = $(this).find('[name$="[pom]"]').val();
    var tolerance = $(this).find('[name$="[tolerance]"]').val();
    if (pom && tolerance) {
      pomAttributes.push(pom + ' (±' + tolerance + ')');
    }
  });
  return pomAttributes;
}

function buildDropdown(options, name) {
  var dropdown = $('<select></select>').attr('name', name).addClass('form-control form-control-sm');
  $.each(options, function(index, option) {
    dropdown.append($('<option></option>').attr('value', option).text(option));
  });
  return dropdown;
}

function buildFabricDropdown(fabricNames, name) {
  var dropdown = $('<select><option value="">Select Fabric</option></select>').attr('name', name).addClass('form-control form-control-sm');
  fabricNames.forEach(function(name) {
    dropdown.append($('<option></option>').attr('value', name).text(name));
  });
  return dropdown;
}

function addSizeEntry() {
  $('#add-size').on('click', function() {
    var sizeInput = $('<input>').attr('type', 'text').attr('name', 'cost_estimate[size][]').addClass('form-control form-control-sm').attr('placeholder', 'Size');
    var removeButton = $('<button type="button"></button>').addClass('btn btn-outline-danger btn-sm remove-size').text('X')
    
    if ($('#size-list .size-label').length === 0) {
      var labelGroup = $('<div></div>').addClass('form-group row size-label');
      labelGroup.append($('<div></div>').addClass('col-sm-2'));
      labelGroup.append($('<div></div>').addClass('col-sm-3').append('<label>Size</label>'));
      $('#size-list').append(labelGroup);
    }

    var formGroup = $('<div></div>').addClass('form-group row');
    formGroup.append($('<div></div>').addClass('col-sm-2'));
    formGroup.append($('<div></div>').addClass('col-sm-3').append(sizeInput));
    formGroup.append($('<div></div>').addClass('col-sm-1').append(removeButton));
    $('#size-list').append(formGroup);

    removeButton.on('click', function() {
      setupEntryRemoval('#size-list', '.remove-size', 'size-label');
      setTimeout(function() {
        updateQuantitySizeRows(); // Update after ensuring DOM updates are reflected
        updateMeasurementSizeDropdowns();
        updatePanelChartRows();
      }, 100);
    });
  });
}

function addPomEntry() {
  $('#add-pom-entry').on('click', function() {
    var pomInput = $('<input>').attr('type', 'text').attr('name', 'cost_estimate[pom_attribute][][pom]').addClass('form-control form-control-sm').attr('placeholder', 'POM');
    var toleranceInput = $('<input>').attr('type', 'text').attr('name', 'cost_estimate[pom_attribute][][tolerance]').addClass('form-control form-control-sm').attr('placeholder', 'Tolerance').attr('value','0');
    var removeButton = $('<button type="button"></button>').addClass('btn btn-outline-danger btn-sm remove-pom-entry').text('X')
    
    if ($('#pom-entry-list .pom-entry-label').length === 0) {
      var labelGroup = $('<div></div>').addClass('form-group row pom-entry-label');
      labelGroup.append($('<div></div>').addClass('col-sm-2'));
      labelGroup.append($('<div></div>').addClass('col-sm-3').append('<label>POM</label>'));
      labelGroup.append($('<div></div>').addClass('col-sm-3').append('<label>Tolerance (±)</label>'));
      $('#pom-entry-list').append(labelGroup);
    }

    var formGroup = $('<div></div>').addClass('form-group row');
    formGroup.append($('<div></div>').addClass('col-sm-2'));
    formGroup.append($('<div></div>').addClass('col-sm-3').append(pomInput));
    formGroup.append($('<div></div>').addClass('col-sm-3').append(toleranceInput));
    formGroup.append($('<div></div>').addClass('col-sm-1').append('<label>CMS</label>'));
    formGroup.append($('<div></div>').addClass('col-sm-1').append(removeButton));
    $('#pom-entry-list').append(formGroup);

    removeButton.on('click', function() {
      setupEntryRemoval('#pom-entry-list', '.remove-pom-entry', 'pom-entry-label');
      setTimeout(updateMeasurementChartEntries, 10);
    });
  });
}


function addQuantityEntry() {
  $('#add-quantity').on('click', function() {
    var colors = getAllColors();
    var sizes = getAllSizes();
    var colorDropdown = buildDropdown(colors, 'cost_estimate[quantity][][color]');
    var sizeDropdown = buildDropdown(sizes, 'cost_estimate[quantity][][size]');
    var quantityInput = $('<input>').attr('type', 'text').attr('name', 'cost_estimate[quantity][][quantity]').attr('value','0').addClass('form-control form-control-sm').attr('placeholder', 'Quantity');
    var removeButton = $('<button type="button"></button>').addClass('btn btn-outline-danger btn-sm remove-quantity').text('X')

    if ($('#quantity-list .quantity-label').length === 0) {
      var labelGroup = $('<div></div>').addClass('form-group row quantity-label');
      labelGroup.append($('<div></div>').addClass('col-sm-2'));
      labelGroup.append($('<div></div>').addClass('col-sm-3').append('<label>Color</label>'));
      labelGroup.append($('<div></div>').addClass('col-sm-3').append('<label>Size</label>'));
      labelGroup.append($('<div></div>').addClass('col-sm-3').append('<label>Quantity</label>'));
      $('#quantity-list').append(labelGroup);
    }

    var formGroup = $('<div></div>').addClass('form-group row');
    formGroup.append($('<div></div>').addClass('col-sm-2'));
    formGroup.append($('<div></div>').addClass('col-sm-3').append(colorDropdown));
    formGroup.append($('<div></div>').addClass('col-sm-3').append(sizeDropdown));
    formGroup.append($('<div></div>').addClass('col-sm-3').append(quantityInput));
    formGroup.append($('<div></div>').addClass('col-sm-1').append(removeButton));

    $('#quantity-list').append(formGroup);
    formGroup.find('select').select2();

    removeButton.on('click', function() {
      setupEntryRemoval('#quantity-list', '.remove-quantity', 'quantity-label');
    });
  });
}

function addMeasurementEntry() {
  $('#add-measurement').on('click', function() {
    var pomAttributes = getAllPomAttributes();
    var sizes = getAllSizes();
    var pomDropdown = buildDropdown(pomAttributes, 'cost_estimate[measurement_attribute][][pom_attribute]');
    var sizeDropdown = buildDropdown(sizes, 'cost_estimate[measurement_attribute][][size]');
    var measurementInput = $('<input>').attr('type', 'text').attr('name', 'cost_estimate[measurement_attribute][][measurement]').addClass('form-control form-control-sm').attr('placeholder', 'Measurement').attr('value','0');
    var removeButton = $('<button type="button"></button>').addClass('btn btn-outline-danger btn-sm remove-measurement').text('X');
    var formGroup = $('<div></div>').addClass('form-group row measurement-label');
    formGroup.append($('<div></div>').addClass('col-sm-2'));
    formGroup.append($('<div></div>').addClass('col-sm-4').append(pomDropdown));
    formGroup.append($('<div></div>').addClass('col-sm-2').append(sizeDropdown));
    formGroup.append($('<div></div>').addClass('col-sm-2').append(measurementInput));
    formGroup.append($('<div></div>').addClass('col-sm-1').append('<label>CMS</label>'));
    formGroup.append($('<div></div>').addClass('col-sm-1').append(removeButton));
 
    $('#measurement-list').append(formGroup);
    formGroup.find('select').select2();

    removeButton.on('click', function() {
      setupEntryRemoval('#measurement-list', '.remove-measurement', 'measurement-label');
      setTimeout(updatePanelChartRows, 10);
    });
  });
}

function addPanelEntry(){
  $('#add-panel').on('click', function() {
    var pomAttributes = getAllMeasurementValues();
    var shapeOptions = ['Rectangle', 'Triangular', 'Trapezoid', 'Circle', 'Other'];
    var panelNameInput = $('<input>').attr('type', 'text').attr('name', 'cost_estimate[panel][][panel_name]').addClass('form-control form-control-sm').attr('placeholder', 'Panel Name');
    var pomDropdown1 = buildDropdown(pomAttributes, 'cost_estimate[panel][][pom_attribute_1]');
    var pomDropdown2 = buildDropdown(pomAttributes, 'cost_estimate[panel][][pom_attribute_2]');
    var shapeDropdown = buildDropdown(shapeOptions, 'cost_estimate[panel][][shape]');
    var removeButton = $('<button type="button"></button>').addClass('btn btn-outline-danger btn-sm remove-panel').text('X');

    var formGroup = $('<div></div>').addClass('form-group row panel-label');
    formGroup.append($('<div class="col-sm-3"></div>').append(panelNameInput));
    formGroup.append($('<div class="col-sm-3"></div>').append(pomDropdown1));
    formGroup.append($('<div class="col-sm-3"></div>').append(pomDropdown2));
    formGroup.append($('<div class="col-sm-2"></div>').append(shapeDropdown));
    formGroup.append($('<div></div>').addClass('col-sm-1').append(removeButton));
    
    $('#panel-list').append(formGroup);
    formGroup.find('select').select2();

    removeButton.on('click', function() {
      setupEntryRemoval('#panel-list', '.remove-panel', 'panel-label');
    });
  });
}

function addTrimEntry() {
  $('#add-trim').on('click', function() {
    getAllTrims();
  });
}

function getAllTrims() {
  $.ajax({
    url: '/cost_estimates/get_trims',
    type: 'GET',
    dataType: 'json',
    success: function(data) {
      var trimDropdown = buildDropdown(data, 'cost_estimate[trim][][name]');
      var countPerPieceInput = $('<input>').attr('type', 'number').attr('name', 'cost_estimate[trim][][count_per_piece]').addClass('form-control form-control-sm').attr('placeholder', 'Count').attr('value','0');
      var removeButton = $('<button type="button"></button>').addClass('btn btn-outline-danger btn-sm remove-trim').text('X')

      if ($('#trim-list .trim-label').length === 0) {
        var labelGroup = $('<div></div>').addClass('form-group row trim-label');
        labelGroup.append($('<div></div>').addClass('col-sm-2'));
        labelGroup.append($('<div></div>').addClass('col-sm-3').append('<label>Name</label>'));
        labelGroup.append($('<div></div>').addClass('col-sm-3').append('<label>Count</label>'));
        $('#trim-list').append(labelGroup);
      }
      var formGroup = $('<div></div>').addClass('form-group row');
      formGroup.append($('<div class="col-sm-2"></div>'));
      formGroup.append($('<div class="col-sm-3"></div>').append(trimDropdown));
      formGroup.append($('<div class="col-sm-3"></div>').append(countPerPieceInput));
      formGroup.append($('<div></div>').addClass('col-sm-1').append('<label>PIECE</label>'));
      formGroup.append($('<div class="col-sm-2"></div>').append(removeButton));

      $('#trim-list').append(formGroup);
      formGroup.find('select').select2();

      removeButton.on('click', function() {
        setupEntryRemoval('#trim-list', '.remove-trim', 'trim-label');
      });
    },
    error: function(error) {
      console.error('Error fetching trims:', error);
    }
  });
}

function fetchFabricTypes() {
  $.ajax({
    url: '/cost_estimates/get_fabric_types',
    type: 'GET',
    dataType: 'json',
    success: function(data) {
      window.fabricTypes = data;
    },
    error: function(error) {
      console.error('Error fetching fabric types:', error);
    }
  });
}

function fetchFabricationProcess() {
  $.ajax({
    url: '/cost_estimates/get_fabrication_processes',
    type: 'GET',
    dataType: 'json',
    success: function(data) {
      window.fabricationProcesses = data;
    },
    error: function(error) {
      console.error('Error fetching fabrication processes:', error);
    }
  });
}

function addFabricEntry() {
  $('#add-fabric').on('click', function() {
    var fabricDropdown = buildFabricDropdown(window.fabricTypes.map(fabric => fabric.name), 'cost_estimate[fabric][][name]');
    fabricDropdown.addClass('fabric-name-dropdown');
    var gsmDropdown = $('<select><option value="">Select GSM</option></select>')
                     .addClass('form-control form-control-sm fabric-gsm-dropdown')
                     .attr('name', 'cost_estimate[fabric][][fabric_gsm]');
    var yarnInput = $('<input>').attr('type', 'text').attr('readonly', true).addClass('form-control form-control-sm yarn-count-input');
    var removeButton = $('<button type="button"></button>').addClass('btn btn-outline-danger btn-sm remove-fabric').text('X');

    if ($('#fabric-list .fabric-label').length === 0) {
      var labelGroup = $('<div></div>').addClass('form-group row fabric-label');
      labelGroup.append($('<div></div>').addClass('col-sm-2'));
      labelGroup.append($('<div></div>').addClass('col-sm-4').append('<label>Fabric</label>'));
      labelGroup.append($('<div></div>').addClass('col-sm-2').append('<label>GSM</label>'));
      labelGroup.append($('<div></div>').addClass('col-sm-2').append('<label>Yarn</label>'));
      $('#fabric-list').append(labelGroup);
    }

    var formGroup = $('<div></div>').addClass('form-group row');
    formGroup.append($('<div class="col-sm-2"></div>'));
    formGroup.append($('<div class="col-sm-4"></div>').append(fabricDropdown));
    formGroup.append($('<div class="col-sm-2"></div>').append(gsmDropdown));
    formGroup.append($('<div class="col-sm-3"></div>').append(yarnInput));
    formGroup.append($('<div class="col-sm-1"></div>').append(removeButton));

    $('#fabric-list').append(formGroup);
    formGroup.find('select').select2();

    var previouslySelectedOptions = $('#fabric-list .fabric-name-dropdown').map(function() {
      return $(this).val();
    }).get();

    fabricDropdown.find('option').each(function() {
      if (previouslySelectedOptions.includes($(this).val())) {
        $(this).prop('disabled', true);
      }
    });

    // Refresh the select2 plugin to reflect the disabled options
    fabricDropdown.select2();

    removeButton.on('click', function() {
      var fabricName = fabricDropdown.val();
      setupEntryRemoval('#fabric-list', '.remove-fabric', 'fabric-label');
      removeFabricColumn(fabricName);
    });

    fabricDropdown.on('change', function() {
      var selectedName = $(this).find('option:selected').text(); // This assumes the dropdown text is what you want to use
      var oldFabricName = $(this).data('old-fabric-name'); // Retrieve the old fabric name stored in data attribute
      
      // Check if the fabric is selected and not the placeholder
      if (selectedName && selectedName !== "Select Fabric") {
        updateGsmDropdown(selectedName, gsmDropdown, yarnInput);
        addFabricColumn(selectedName, oldFabricName);
        addProcessCheckboxes();
        $(this).data('old-fabric-name', selectedName);
      }

      $('#fabric-list .fabric-name-dropdown').not(this).each(function() {
        var otherDropdown = $(this);
        var thisSelectedValue = fabricDropdown.val();

        otherDropdown.find('option[value="' + thisSelectedValue + '"]').prop('disabled', true);
        otherDropdown.find('option[value="' + oldFabricName + '"]').prop('disabled', false);
        otherDropdown.select2();
      });
    });

    gsmDropdown.on('change', function() {
      var selectedGsm = $(this).val();
      var selectedFabric = window.fabricTypes.find(fabric => fabric.name === fabricDropdown.val());
      var fabricVariant = selectedFabric.variants.find(variant => variant.fabric_gsm.toString() === selectedGsm);
      yarnInput.val(fabricVariant ? fabricVariant.yarn_counts : '');
    });
  });
}

function updateGsmDropdown(selectedFabricName, gsmDropdown, yarnInput) {
  var fabricData = window.fabricTypes.find(fabric => fabric.name === selectedFabricName);
  gsmDropdown.empty().append('<option value="">Select GSM</option>');
  fabricData.variants.forEach(function(variant) {
    gsmDropdown.append($('<option></option>').attr('value', variant.fabric_gsm).text(variant.fabric_gsm));
  });
  yarnInput.val('');
}

function addFabricColumn(selectedFabricName, oldFabricName) {
  var fabricHeaderRow = $('#fabric-process-table thead tr');
  var indexToUpdate = -1;

  // Determine if the column already exists and get its index
  fabricHeaderRow.find('th').each(function(index) {
    if ($(this).text() === oldFabricName) {
      indexToUpdate = index;
      return false; // break the loop
    }
  });

  if (indexToUpdate !== -1) {
    // Update the existing column header and all corresponding checkboxes
    fabricHeaderRow.find('th').eq(indexToUpdate).text(selectedFabricName);
    $('#fabric-process-table tbody tr').each(function() {
      $(this).find('td').eq(indexToUpdate).find('input').each(function() {
        var nameAttr = $(this).attr('name');
        var newNameAttr = nameAttr.replace(oldFabricName, selectedFabricName);
        $(this).attr('name', newNameAttr);
      });
    });
  } else {
    // If the column does not exist, create it
    fabricHeaderRow.append('<th>' + selectedFabricName + '</th>');
    // Also add a new checkbox cell in each row for the new fabric
    $('#fabric-process-table tbody tr').each(function() {
      var processName = $(this).find('td:first').text().trim();
      var inputName = 'cost_estimate[fabrication_process_attribute][' + processName + '][' + selectedFabricName + ']';
      $(this).append('<td><input type="checkbox" name="' + inputName + '" value="true"><input type="hidden" name="' + inputName + '" value="false"></td>');
    });
  }
}


function addProcessCheckboxes() {
  var processes = window.fabricationProcesses;
  var processTableBody = $('#fabric-process-table tbody');
  processTableBody.empty(); // Clear existing rows for a clean slate

  processes.forEach(function(processName) {
    var row = $('<tr></tr>').append($('<td></td>').text(processName));
    $('#fabric-process-table thead th:not(:first)').each(function() { // Skip the first "Process" header cell
      var fabricName = $(this).text();
      var inputName = 'cost_estimate[fabrication_process_attribute][' + processName + '][' + fabricName + ']';
      var checkboxCell = $('<td></td>').append(
        $('<input>').attr({type: 'hidden', name: inputName, value: 'false'}),
        $('<input>').attr({type: 'checkbox', name: inputName, value: 'true'})
      );
      row.append(checkboxCell);
    });
    processTableBody.append(row);
  });
}


function removeFabricColumn(fabricName) {
  var fabricHeaderRow = $('#fabric-process-table thead tr');
  fabricHeaderRow.find('th').each(function() {
      if ($(this).text() === fabricName) {
          var index = $(this).index(); // Get index of the column to remove
          fabricHeaderRow.find('th:eq(' + index + ')').remove(); // Remove header
          $('#fabric-process-table tbody tr').each(function() {
              $(this).find('td:eq(' + index + ')').remove(); // Remove cells in the same column
          });
      }
  });

  if ($('#fabric-process-table thead th').length <= 1) {
    $('#fabric-process-table tbody').empty(); // Clear all rows from the table body
  }
}

function updateQuantityColorDropdowns() {
  $('#color').on('change', function() {
    var selectedColors = getAllColors(); // Get all currently selected colors

    // Remove quantity entries whose colors are no longer selected
    $('#quantity-list .form-group').each(function() {
      if (!$(this).hasClass('quantity-label')) {
        var colorDropdown = $(this).find('[name="cost_estimate[quantity][][color]"]');
        var currentColor = colorDropdown.val();
        if (selectedColors.indexOf(currentColor) === -1) {
          $(this).remove();
        }
      }
    });

    // Update the options in all remaining color dropdowns
    $('#quantity-list .form-group').find('[name="cost_estimate[quantity][][color]"]').each(function() {
      var dropdown = $(this);
      var currentValue = dropdown.val();
      dropdown.empty(); // Clear current options
      selectedColors.forEach(function(color) {
          var option = $('<option>').val(color).text(color);
          dropdown.append(option);
      });
      dropdown.val(currentValue); // Re-set the original value if still available
    });

    // Finally, check if we need to remove the label if there are no more entries
    if ($('#quantity-list .form-group').length === 0) {
      $('#quantity-list .quantity-label').remove();
    }
  });
}

function updateQuantitySizeRows() {
  var selectedSizes = getAllSizes(); // Fetch all selected sizes

  // Remove entries not matching any selected size
  $('#quantity-list .form-group').each(function() {
    if (!$(this).hasClass('quantity-label')) {
        var sizeDropdown = $(this).find('[name="cost_estimate[quantity][][size]"]');
        var currentSize = sizeDropdown.val();

        if (!selectedSizes.includes(currentSize)) {
            $(this).remove();
        }
    }
  });

  // Update the options in all remaining size dropdowns to reflect current selections
  $('#quantity-list .form-group').find('[name="cost_estimate[quantity][][size]"]').each(function() {
      var dropdown = $(this);
      var currentValue = dropdown.val();
      dropdown.empty(); // Clear current options
      selectedSizes.forEach(function(size) {
          dropdown.append($('<option>').val(size).text(size));
      });
      dropdown.val(currentValue); // Re-set the original value if still available
  });
  saveFormData();
}

function updateMeasurementSizeDropdowns() {
  var selectedSizes = getAllSizes(); // Fetch all selected sizes

  // Iterate over each measurement entry
  $('#measurement-list .form-group').each(function() {
      var sizeDropdown = $(this).find('[name="cost_estimate[measurement_attribute][][size]"]');
      var currentSize = sizeDropdown.val();

      // If the current size is not in the selected sizes, remove this group
      if (!selectedSizes.includes(currentSize)) {
          $(this).remove();
      }
  });

  // Update the options in all remaining size dropdowns to reflect current selections
  $('#measurement-list .form-group').find('[name="cost_estimate[measurement_attribute][][size]"]').each(function() {
      var dropdown = $(this);
      var currentValue = dropdown.val();
      dropdown.empty(); // Clear current options
      selectedSizes.forEach(function(size) {
          dropdown.append($('<option>').val(size).text(size));
      });
      dropdown.val(currentValue); // Re-set the original value if still available
  });
  saveFormData();
}

function updateMeasurementChartEntries() {
  var selectedPoms = getAllPomAttributes(); // Fetch all current POM entries

  // Iterate over each measurement entry
  $('#measurement-list .form-group').each(function() {
    var pomDropdown = $(this).find('[name="cost_estimate[measurement_attribute][][pom_attribute]"]');
    var currentPom = pomDropdown.val();

    // If the current POM is not in the selected POMs, remove this measurement group
    if (!selectedPoms.includes(currentPom)) {
        $(this).remove();
    }
  });

  $('#measurement-list .form-group').find('[name="cost_estimate[measurement_attribute][][pom_attribute]"]').each(function() {
    var dropdown = $(this);
    var currentValue = dropdown.val();
    dropdown.empty(); // Clear current options
    selectedPoms.forEach(function(pom) {
        dropdown.append($('<option>').val(pom).text(pom));
    });
    dropdown.val(currentValue); // Restore previous selection if still valid
  });
  saveFormData();
}

function getAllMeasurementValues() {
  var measurements = [];
  $('#measurement-list .form-group').each(function() {
    var measurementDropdown = $(this).find('[name="cost_estimate[measurement_attribute][][pom_attribute]"]').val();
    var sizeAttribute = $(this).find('[name^="cost_estimate[measurement_attribute][][size]"]').val();
    if (measurementDropdown.length > 0) {
        measurements.push(measurementDropdown + " (" + sizeAttribute + ")");
    }
  });
  return measurements;
}

function updatePanelChartRows() {
  var currentMeasurements = getAllMeasurementValues(); // Fetch current Measurement Chart values

  $('#panel-list .form-group').each(function() {
    // Obtain the current POM attribute values for both dropdowns in the panel
    var panelPomAttribute1 = $(this).find('[name="cost_estimate[panel][][pom_attribute_1]"]').val();
    var panelPomAttribute2 = $(this).find('[name="cost_estimate[panel][][pom_attribute_2]"]').val();

    if (!currentMeasurements.includes(panelPomAttribute1) || !currentMeasurements.includes(panelPomAttribute2)) {
      $(this).remove();
    }
  });
}






