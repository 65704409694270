// Employee inputs initialisation & events
window.initialise_emp_inputs = function(){
  var mobileInput = document.querySelector("#employee_mobile");
  if(mobileInput){
    // init plugin
    var iti = window.intlTelInput(mobileInput, {
      formatOnInit: true,
      nationalMode: false,
      initialCountry: "auto",
      geoIpLookup: function(callback) {
        $.get('https://ipinfo.io', function() {}, "jsonp").always(function(resp) {
          var countryCode = (resp && resp.country) ? resp.country : "us";
          callback(countryCode);
        });
      },
      autoHideDialCode: false,
      utilsScript: utilsScript
    });

    // setting country dial code
    mobileInput.addEventListener("countrychange", function() {
      var countryData = iti.getSelectedCountryData()
      $("#employee_mobile").val("+" + countryData.dialCode);
    });
  }

  // Preventing to remove country dial code
  $("#employee_mobile").on("keydown", function(e){
    var countryData = iti.getSelectedCountryData()
    var dialCode = "+" + countryData.dialCode;
    var mobile_value = $(this).val();
    if((e.keyCode == 8 || e.keyCode == 46 ) &&  mobile_value == dialCode){
      return false;
    }
  })

  $("#employee_mobile").on("keyup", function(e){
    var countryData = iti.getSelectedCountryData()
    var dialCode = "+" + countryData.dialCode;
    var mobile_value = $(this).val();
    if(mobile_value == "" || mobile_value == undefined){
      $(this).val(dialCode)
    }
  })

  // init daterangepicker
  $('.dob, .doj').daterangepicker({
    autoUpdateInput: false,
    locale: {
      format: "DD-MM-YYYY"
    },
    singleDatePicker: true,
    showDropdowns: true,
    minYear: 1930,
    maxYear: parseInt(moment().format('YYYY')),
    maxDate: moment()
  });

  $('.dob, .doj').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('DD-MM-YYYY'));
    if($(this).hasClass("dob")){
      var age = getAge(picker.startDate);
      $("#employee_age").val(age);
    }
  });

  $('.dob, .dpj').on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
  });
}

$(document).on('turbolinks:load', function(){
  initialise_emp_inputs();
});
