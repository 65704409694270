$(document).on("turbolinks:load", function () {
  $(".purchase-item-form #quantity").on("change", function () {
    updateTotalPriceOnUi()
  });

  $('#check-code-link').on('click', function(){
    var url = $('#check-code-link').data('url')
    var data = {}
    data['coupon_code'] = $('#coupon_code').val()
    data['sku_type'] = $('#sensor-type').data("sku-type")

    $('#coupon-status-error').html('')
    $('#coupon-status-success').html('')
    $('#coupon-discount-span').hide()
    $('#coupon-discount-span span').text('')
    $('.clear-btn').hide()
    updateTotalPriceOnUi()
    if(data['coupon_code'] != undefined && data['coupon_code'] != ''){
      $.ajax({url: url, data: data})
    }
  })

  // prevent form submit on enter, and check for coupon validity
  $('#coupon_code').on('keydown', function(e){
    if(e.originalEvent.key == 'Enter'){
      e.preventDefault()
      $('#check-code-link').trigger('click')
    }
  })

  $('#coupon_code').on('change', function(e){
    if($('#coupon_code').val() != ''){
      $('.clear-btn').show()
    }
  })

  $('.clear-btn').on('click', function() {
    $('#coupon_code').val('')
    $('#check-code-link').trigger('click')
    $('#coupon-status-error').html('cleared')
    $('#coupon-status-success').html('')
    $('.clear-btn').hide()
  })
});

function updateTotalPriceOnUi(couponDiscount=null) {
  if(couponDiscount==null){
    couponDiscount = parseFloat($('#coupon-discount-span span').text())

    couponDiscount = (!couponDiscount) ? 0 : couponDiscount
  }
  var qty = parseFloat($('#quantity').val())
  var price = parseFloat($('#sensor-price').data("sku-price"))
  var total = ''
  var gross = ''
  if(!!qty && !!price){
    gross = (price*qty).toFixed(2)
    total = ((price*qty) - couponDiscount).toFixed(2)
    total = (total < 0) ? 0 : total
  }
  $('.purchase-item-form .total-price').text(total)
  $('#gross-amount-span span').text(gross)
}

window.updateTotalPriceOnUi = updateTotalPriceOnUi

function openRzpPopupForSku(rzp_data, is_plan=false){
  rzp_data["handler"] =  function (response){
    if(is_plan == true) {
      var url = "/skus/my_plans"
    }else{
      var url = "/skus"
    }

    $(location).attr('href', url)
  }

  modal_data = rzp_data["modal"]
  modal_data["ondismiss"] = function(){
    var params = {
      organisation_id: rzp_data['organisation_id'],
      order_id: rzp_data['app_order_id'],
      payment_number: rzp_data["notes"]["payment_number"],
      status: "Cancelled"
    }
    var url = "/orders/"+ rzp_data['app_order_id'] +"/update_payment_status"
    $.post(url, params, function(data){

      if(data.success == true){
        showMsg("Payment Cancelled", "failure")
      }else{
        showMsg(data.error, "failure")
      }
    });
  }

  rzp_data["modal"] = modal_data
  var rzp = new Razorpay(rzp_data);
  rzp.open();

  // rzp.on('payment.failed', function (response){
  //   var error_reason = response.error.reason
  //   var params = {
  //     organisation_id: rzp_data['organisation_id'],
  //     order_id: rzp_data['app_order_id'],
  //     payment_number: rzp_data["notes"]["payment_number"],
  //     status: "Failed"
  //   }
  //   var url = "/orders/"+ rzp_data['app_order_id'] +"/update_payment_status"
  //   $.post(url, params, function(data){
  //     if(data.success == true){
  //       showMsg(error_reason, "failure")
  //     }else{
  //       showMsg(data.error, "failure")
  //     }
  //   });
  // });

  // rzp.on('payment.captured', function (response){
  //   var params = {
  //     organisation_id: rzp_data['organisation_id'],
  //     order_id: rzp_data['app_order_id'],
  //     payment_number: rzp_data["notes"]["payment_number"],
  //     status: "Paid"
  //   }
  //   var url = "/orders/"+ rzp_data['app_order_id'] +"/update_payment_status"
  //   $.post(url, params, function(data){
  //     if(data.success == true){
  //       showMsg(error_reason, "success")
  //     }else{
  //       showMsg(data.error, "failure")
  //     }
  //   });

  // })
}

window.openRzpPopupForSku = openRzpPopupForSku

$(document).on("turbolinks:load", function () {
  $('#sku_sku_type').on('change', function(){
    if( $(this).val() == 'subscription_plan' ){
      $('#sku_validity_period').removeAttr('disabled').removeAttr('title').removeClass('not-allowed-cursor')
    }
    else {
      $('#sku_validity_period').attr('disabled', true).attr('title', 'Add-on plans have no Validity').addClass('not-allowed-cursor').val('')
    }
  })
})
