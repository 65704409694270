// Defect Marker
// Expects #thing to be present in the HTML to show marked position
window.addMarker = function(posX, posY, id = ""){
  var node = document.createElement("div");
  node.id = id;
  node.classList.add('defect-marker');
  node.style.left = (posX /  container.getBoundingClientRect().width)*100 + "%";
  node.style.top = (posY /  container.getBoundingClientRect().height)*100 + "%";
  
  container.append(node);
};

window.addValue = function(posX, posY, step_id = ""){
  if(step_id === "")
    step_id = $("#step_id").val();
  defect_points.push({pos_x: posX, pos_y: posY, operation_bulletin_step_id: step_id });
  $("#defectCount").html(defect_points.length);
};

window.addHeatMarker = function(point){
  var node = document.createElement("div");
  node.id = point.id;
  node.classList.add('heat-marker-s'+point.value);
  node.style.left = (point.x /  container.getBoundingClientRect().width)*100 + "%";
  node.style.top = (point.y /  container.getBoundingClientRect().height)*100 + "%";
  $(node).attr("data-toggle", "tooltip");
  $(node).attr("title", point.text);
  
  container.append(node);
};

window.setImageMarker = function(imageContainer){
  var theThing = document.querySelector("#thing");

  container.addEventListener("click", function(event) {

    var xPosition = event.clientX - container.getBoundingClientRect().left - (theThing.clientWidth / 2);
    var yPosition = event.clientY - container.getBoundingClientRect().top - (theThing.clientHeight / 2);
    // in case of a wide border, the boarder-width needs to be considered in the formula above
    theThing.style.left =  (xPosition /  container.getBoundingClientRect().width)*100 + "%";
    theThing.style.top = (yPosition /  container.getBoundingClientRect().height)*100 + "%";

    // Mark UI position and add in HTML as hidden input
    addMarker(xPosition, yPosition);
    addValue(xPosition, yPosition);
  });  
  
  $("#saveDefects").click(function(){
    $("#inputMarkers").html("");
    // Using async so that we have set all inputs before posting form
    const set_inputs = async () => {
      defect_points.map(function(p, ix){     
        const input_x = `<input type="hidden" name="markers[${ix}][pos_x]" value="${p.pos_x}"/>`;
        const input_y = `<input type="hidden" name="markers[${ix}][pos_y]" value="${p.pos_y}"/>`;
        const input_s = `<input type="integer" name="markers[${ix}][operation_bulletin_step_id]" value="${p.operation_bulletin_step_id}"/>`;

        $("#inputMarkers").append(input_x, input_y, input_s);
      })
    };

    set_inputs().then(function(){
      $("#defectsForm").submit();
    })
  });

}
