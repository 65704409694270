
$(document).on("turbolinks:load", function () {
  $('#coupon_template_for_new_organisations').on('change', function(){
    if(this.checked) {
      $('#coupon_template_organisation_ids').attr('disabled', true)
      $('#coupon_template_expiry_date').attr('disabled', true)
      $('#select-all-organisation').prop('checked', false).trigger('change')
      $('#normal-template-field-container').addClass('d-none')
      $('#org-only-template-field-container').removeClass('d-none')
    }else {
      $('#coupon_template_organisation_ids').removeAttr('disabled')
      $('#coupon_template_expiry_date').removeAttr('disabled')
      $('#normal-template-field-container').removeClass('d-none')
      $('#org-only-template-field-container').addClass('d-none')
      $('#coupon_template_organisation_ids').select2('destroy').select2();

    }
  })
})
