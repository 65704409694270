window.machine_selectpicker = function () {
  $(".m-selectpicker")
    .selectpicker()
    .ajaxSelectPicker({
      ajax: {
        url: "/sensors/suggest",
        type: "GET",
        data: function () {
          var params = {
            text: "{{{q}}}",
            organisation_id: $(".m-selectpicker").attr("id"),
            sensor_type_id: $("#m_sensor_type").val(),
            position_id: $(".m-selectpicker").attr("data-position-id")
          };
          return params;
        },
      },
      locale: {
        emptyTitle: "Search for Sensors...",
      },
      preprocessData: function (data) {
        var sensors = [];
        var len = data.length;
        for (var i = 0; i < len; i++) {
          var sensor = data[i];
          sensors.push({
            value: sensor.id,
            text: sensor.name + " - " + sensor.serial_number,
            disabled: false,
          });
        }
        return sensors;
      },
      preserveSelected: false,
      ignoredKeys: ignored_keys_list(),
      emptyRequest: true,
    });
};

window.ms_selectpicker = function () {
  $(".ms-selectpicker")
    .selectpicker()
    .ajaxSelectPicker({
      ajax: {
        url:
          "/machines/" + $(".ms-selectpicker").attr("id") + "/suggest_skills",
        type: "GET",
        data: function () {
          var params = {
            text: "{{{q}}}",
          };
          return params;
        },
      },
      locale: {
        emptyTitle: "Search for Skills...",
      },
      preprocessData: function (data) {
        var skills = [];
        var len = data.length;
        for (var i = 0; i < len; i++) {
          var skill = data[i];
          skills.push({
            value: skill.id,
            text: skill.name,
            disabled: false,
          });
        }
        return skills;
      },
      preserveSelected: true,
      preserveSelectedPosition: "after",
      ignoredKeys: ignored_keys_list(),
      emptyRequest: true,
    });
};

window.mob_selectpicker = function () {
  $(".mob-selectpicker")
    .selectpicker()
    .ajaxSelectPicker({
      ajax: {
        url:
          "/machines/" +
          $(".mob-selectpicker").attr("id") +
          "/suggest_operation_bulletin_steps",
        type: "GET",
        data: function () {
          var params = {
            text: "{{{q}}}",
          };
          return params;
        },
      },
      locale: {
        emptyTitle: "Search for Operation Bulletin Step...",
      },
      preprocessData: function (data) {
        var ob_steps = [];
        var len = data.length;
        for (var i = 0; i < len; i++) {
          var obs = data[i];
          ob_steps.push({
            value: obs.id,
            text: obs.ob_name + " - " + obs.name,
            disabled: false,
          });
        }
        return ob_steps;
      },
      preserveSelected: true,
      preserveSelectedPosition: "after",
      ignoredKeys: ignored_keys_list(),
      emptyRequest: true,
    });
};

$(document).on("turbolinks:load", function () {
  machine_selectpicker();
  ms_selectpicker();
  mob_selectpicker();
  $(".deactivate, .ms-remove, .mobs-remove").on("click", function () {
    if (confirm("Are you sure you want to unassign?")) {
      var relation_id = $(this).attr("id");
      var machine_id = $(this).attr("data-machine-id");
      var action = $(this).attr("data-action");
      var url = "/machines/" + machine_id + "/" + action;
      $.post(
        url,
        {
          authenticity_token: $('[name="csrf-token"]')[0].content,
          relation_id: relation_id,
        },
        function (data, status) {}
      );
    }
  });
});
